module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_esli_akq5sopm2xmzk3zxrdyugikclq/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent","showCaptions":false,"markdownCaptions":false,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@1_ywmqwgzt23dzvhacw2enmgp3au/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-theme-ui@0.15.7_@emotion+react@11.14.0_@types+react@18.3.16_react@18.3.1__@mdx-_ia3zrvekmdsat6q37gvckqeyge/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3_etms2653txrxyaeujj4fzcidce/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dipack's personal website","short_name":"dipack-personal-website","description":"Dipack's personal website.","start_url":"/","background_color":"#fff","theme_color":"#6B46C1","display":"standalone","icon":"static/futurama-bender.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8c9a5c6717df3e07d4935baa4951b639"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__m7bhun7j4jwmjhq732t6ktsmxi/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
